//hooks
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'
//context
import { useThemeContext } from '../../contexts/themeContext'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useParams } from 'react-router-dom'
//components
import { InputAutoComplete } from '../input/input.autocomplete'
import { LabelInput } from '../label/label.input'
import { Button } from '../buttons/button.default'
import { OperatorScheduleCreate } from '../../pages/schedule/operator.schedule.create'
//utils
import { getPartnersHours, getClients } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { hourTime } from '../calendar/hourTime'
import { ValidPeriodTime } from '../../utils/validators/hours.validator'
import { useAuth } from '../../contexts/useAuth'
import { responseError } from '../../utils/responsesFunctions/error.response'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import moment from 'moment'
import { maskHours } from '../../utils/mask/maskHours'
//icons
import { LoaderWBg } from '../loaders/loaderWBg'
import { FaRegCalendar, FaRegCalendarCheck, FaRegCalendarTimes, FaRegClock, FaRegFrown, FaRegLaughBeam, FaRegWindowClose, FaShare } from 'react-icons/fa'

export function DayCalendarPartner({ events, partner, selectedDay, editEvent, cursor }) {
    const { userData, reloadData, setReloadData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [hoursPush, setHoursPush] = useState([])

    const [partnersList, setPartnersList] = useState([])
    const [myEvents, setMyEvents] = useState([])

    const eventRef = useRef([createRef()])
    const [findThisHour, setFindThisHour] = useState(false)
    const now = parseInt(moment().format(`HH`))
    const nowMinutes = parseInt(moment().format(`mm`)) >= 30 ? .5 : 0

    const [partnersWithEvents, setPartnersWithEvents] = useState([])

    async function getData() {
        setLoading(true)
        try {

            const partners = await getPartnersHours(userData.token)
            setPartnersList(partners.data)
            setLoading(true)
            if (partners?.data?.length > 0) {
                const partnersEvents = partnersList?.filter(partner => {
                    const eventsThisPartner = events.data.filter(event => event.partners_id === partner.id)
                    if (eventsThisPartner.length > 0) {
                        return partner
                    }
                })

                partner ? partner?.id === 0 ? setPartnersWithEvents(partnersEvents) : setPartnersWithEvents([partner]) : setPartnersWithEvents(partnersEvents)
            }
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    useEffect(() => {

        getData()

        let totalHours = 0
        while (totalHours < 24) {
            Array(48).fill(0).map(() => {
                let thisDescription = totalHours.toString().indexOf('.5') > -1 ? totalHours?.toString()?.replace('.5', '') + ':30' : totalHours + ':00'
                hoursPush.push({ 'hour': totalHours, 'description': thisDescription })
                totalHours = totalHours + (1 / 2)
            })
        }
        setHoursPush(hoursPush)
    }, [partner])

    useEffect(() => {
        setLoading(true)
        setMyEvents(events?.data)
        getData()
    }, [events])

    useEffect(() => {

        if (!findThisHour) {
            setFindThisHour(true)
        }

        document?.querySelector('#thisHour')?.scrollIntoView({
            behavior: 'smooth'
        });
        //

    }, [findThisHour])

    function mouseDown(e) {
        window.addEventListener(`mousemove`, (e) => eventMove(e))
    }

    function mouseUp(e) {
        window.removeEventListener(`mousemove`, (e) => eventMove(e))
    }

    function eventMove(e) {
        console.log(e)
    }

    function editThisEvent(event) {
        editEvent(event)
    }

    return (
        <>

            <div id='calendarDayComponent' className='flex flex-row sm:w-[350px] md:w-[450px] lg:w-[650px] xl:w-[900px] 2xl:w-[1150px]'>
                {
                    loading ?
                        <div className='flex flex-row'>

                            <div className='w-full fixed left-[58%] top-[50%] items-center justify-center'>
                                {/* <LoaderWBg /> */}
                            </div>
                            {
                                hourTime.map((hour) => {
                                    let hourEvent = false
                                    return <div id={hour.hour === now + nowMinutes ? 'thisHour' : hour.hour + '-id'} onDrop={(e) => dropEvent(e)} className='min-w-20 w-24 flex flex-col gap-1 items-start justify-start border-l dark:border-primaryBorderDark h-[1000px] dark:bg-thirdDefaultDark'>
                                        <div className={`${hour.hour === now + nowMinutes ? `bg-blue-300 dark:bg-blue-800` : `dark:bg-thirdDefaultDark bg-bgPrimaryLight`} w-24 top-0 sticky text-titleBlackTextLight dark:text-titleGrayTextDark shadow-md z-[99] flex flex-col items-center justify-center h-8 border-b border-gray-200 dark:border-primaryBorderDark mb-1`}>
                                            <a className='text-xs to-titleBlackTextLight'>{maskHours(hour)}</a>
                                        </div>
                                        <div className='flex flex-col gap-4 relative '>
                                            {
                                                partnersWithEvents.map((partner) => {
                                                    hourEvent = false
                                                    return <div className='flex flex-row relative w-24 min-h-28 h-28'>
                                                        {
                                                            !hourEvent &&
                                                            <a className='text-transparent bg-zinc-200 opacity-25 w-full'>.</a>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        :
                        hourTime.map((hour, indexHour) => {
                            let hourEvent = false
                            return <div id={hour.hour === now + nowMinutes ? 'thisHour' : hour.hour + '-id'} onDrop={(e) => dropEvent(e)} className='min-w-20 w-24 flex flex-col gap-1 items-start justify-start border-l dark:border-primaryBorderDark h-[1000px] dark:bg-thirdDefaultDark '>
                                <div className={`${hour.hour === now + nowMinutes ? `bg-blue-300 dark:bg-blue-800` : `dark:bg-thirdDefaultDark bg-bgPrimaryLight`} w-24 top-0 sticky text-titleBlackTextLight dark:text-titleGrayTextDark shadow-md z-[99] flex flex-col items-center justify-center h-8 border-b border-gray-200 dark:border-primaryBorderDark mb-1`}>
                                    <a className='text-xs to-titleBlackTextLight'>{maskHours(hour)}</a>
                                </div>
                                <div className='flex flex-col gap-4 relative '>
                                    {
                                        partnersWithEvents.map((partner) => {
                                            hourEvent = false
                                            return <div className='flex flex-row relative w-24 min-h-28 h-28'>
                                                {
                                                    myEvents?.length > 0 &&
                                                    myEvents.map((event, indexEvent) => {
                                                        let text = `Parceiro: ${partner.name} agendado com: ${event.firstname}`
                                                        let numberBlocks = (event.finishHour - event.startHour) / 0.25
                                                        let blocks = Array(numberBlocks).fill(0)
                                                        let heightBlock = (numberBlocks * 95) + 'px'

                                                        if (partner.id === event.partners_id && (event.startHour === hour.hour)) {

                                                            let thisStartHour = maskHours(hour)
                                                            let thisFinishHour = maskHours({ hour: event.finishHour })

                                                            return <Tippy zIndex={100} content={<div className='gap-2 flex flex-col'>
                                                                <a className='text-xs mt-1 font-semibold'>{`${thisStartHour} as ${thisFinishHour} - ${event.status === 0? 'Confirmado' : '' || event.status === 1 ? 'Não compareceu' : '' || event.status === 2 ? 'Pendente' : '' || event.status === 3 ? 'Contrato assinado' : '' || event.status === 4 ? 'Sem direito' : event.status === 5 ? 'Reagendado' : event.status === 6 ? 'Desmarcado' : event.status === 7 ? 'Sem interesse' : '' }  `}</a>                                                                
                                                                <a className='text-xs'><p>{`Parceiro: ${partner.name.toUpperCase()}`}</p><p>{`Cliente: ${event.firstname.toUpperCase()} - ${event?.clients_id}`}</p></a>
                                                            </div>
                                                            }
                                                                arrow={true}
                                                                animation='shift-away'
                                                                placement='bottom'
                                                                delay={100}>
                                                                <div onClick={() => editThisEvent(event)}
                                                                    ref={e => eventRef.current[indexEvent] = e}
                                                                    style={{ width: `${heightBlock}` }}
                                                                    className={`h-full ${cursor ? cursor : ''}                                                      
                                                                    ${event.status === 1 ? 'border-orange-400 bg-orange-300 dark:border-orange-700 dark:bg-orange-600' :
                                                                            event.status === 2 ? 'border-red-400 bg-red-300 dark:border-red-900 dark:bg-red-800' :
                                                                                event.status === 3 ? 'border-teal-600 bg-teal-500 dark:border-teal-800 dark:bg-teal-700' :
                                                                                    event.status === 4 ? 'border-yellow-300 bg-yellow-200 dark:border-yellow-700 dark:bg-yellow-600' :
                                                                                        event.status === 5 ? 'border-sky-300 bg-sky-200 dark:border-sky-700 dark:bg-sky-600' : 
                                                                                            event.status === 6 ? 'border-red-700 bg-red-500 dark:border-red-700 dark:bg-red-600' : 
                                                                                                event.status === 7 ? 'border-gray-700 bg-gray-500 dark:border-gray-700 dark:bg-gray-600' : 'bg-blue-300 dark:bg-blue-800 dark:border-blue-900 border-blue-400'}        
                                                                     h-20 p-1 overflow-hidden shadow-lg border-l-8 absolute w-28  z-20 flex flex-col justify-start items-start pl-2 hover:brightness-90 cursor-pointer`}
                                                                >
                                                                    <a className='dark:text-white text-xs mt-1 font-semibold'>{`${thisStartHour} as ${thisFinishHour}`}</a>
                                                                    <a className='dark:text-white text-xs'>{text.slice(0, 30).concat(`...`)}</a>
                                                                    <div className={`flex flex-col items-center justify-center rounded-full w-8 h-8 border 
                                                                    ${event.status === 1 ? 'border-orange-200 bg-orange-200 dark:border-orange-500 dark:bg-orange-500' :
                                                                            event.status === 2 ? 'border-red-200 bg-red-200 dark:border-red-700 dark:bg-red-700' :
                                                                                event.status === 3 ? 'border-teal-400 bg-teal-400 dark:border-teal-600 dark:bg-teal-600' :
                                                                                    event.status === 4 ? 'border-yellow-100 bg-yellow-100 dark:border-yellow-500 dark:bg-yellow-500' : 
                                                                                        event.status === 5 ? 'border-sky-100 bg-sky-100 dark:border-sky-600 dark:bg-sky-500' : 
                                                                                            event.status === 6 ? 'border-red-500 bg-red-400 dark:border-red-600 dark:bg-red-500' : 
                                                                                                event.status === 7 ? 'border-gray-500 bg-gray-400 dark:border-gray-600 dark:bg-gray-500' : 'border-blue-200 bg-blue-200 dark:border-blue-600 dark:bg-blue-600'}                                                                                                                                                          
                                                                     absolute right-2 bottom-2 shadow-md`}>
                                                                        {
                                                                            event.status === 1 ? <FaRegFrown className='text-lg' /> :
                                                                                event.status === 2 ? <FaRegClock className='text-lg' /> :
                                                                                    event.status === 3 ? <FaRegLaughBeam className='text-lg' /> :
                                                                                        event.status === 4 ? <FaRegWindowClose className='text-lg' /> :
                                                                                            event.status === 5 ? <FaShare className='text-lg'/> : 
                                                                                                event?.status === 6 ? <FaRegCalendarTimes className='text-lg'/> : 
                                                                                                    event?.status === 7 ? <FaRegCalendar className='text-lg'/> : <FaRegCalendarCheck className='text-lg' />
                                                                        }
                                                                    </div>
                                                                    {blocks.map(block => {
                                                                        return <div className='top-0 absolute text-transparent'>
                                                                            <a>.</a>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </Tippy>
                                                        }
                                                    })
                                                }
                                                {
                                                    !hourEvent &&
                                                    <a className='text-transparent bg-zinc-200 opacity-25 w-full'>.</a>
                                                }
                                            </div>
                                        })

                                    }
                                </div>
                            </div>
                        })
                }
            </div>
        </>
    )
}