import React, { useEffect, useState } from 'react'
//components
import { Body } from '../components/container/Body'
import { Container } from '../components/container/container'
import { Title } from '../components/titlePages/title'
import { TitlePage } from '../components/titlePages/title.page'
import { BarsGraph } from '../components/dashboard/bars'
import { SliceGraph } from '../components/dashboard/slice'
import { NewSliceGraph } from '../components/dashboard/newSlice'
import { DefaultLoader } from '../components/loaders/defaultLoader'
import { Button } from '../components/buttons/button.default'
import { InputAutoComplete } from '../components/input/input.autocomplete'
import { Input } from '../components/input/input'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//api
import { getDashboard, getDashboardToOperator, getLinkedSupervisorToOperator, getLinkePartnersWithConsultant, getPartners, getUsers } from '../services/api/callAPIsFunctions/defaultCalls.api'
import { getSignatedContract, getSignatedContractById } from '../services/api/callAPIsFunctions/defaultCalls.api'
//context
import { useAuth } from '../contexts/useAuth'
import { OperatorSchedule } from './schedule/operator.schedule'
import { useParams } from 'react-router-dom'
import { PartnerSchedule } from './partnerSchedule/partner.schedule'
import { FiSlash } from 'react-icons/fi'
import { api } from '../services/api/api'
import { TableDefault } from '../components/table/table.default'
import { FaSearch } from 'react-icons/fa'
import { contextSchedule } from '../contexts/schedulesContext'

export function Home() {
    const { id } = useParams()

    const { selectedMode, setSelectedMode } = contextSchedule()

    const [loading, setLoading] = useState(true)
    const [dashData, setDashData] = useState([])
    const [selectedDashboardData, setSelectedDashboardData] = useState({})
    const [showScheduleTable, setShowScheduleTable] = useState(false)
    const [dataSignatedContract, setDataSignatedContract] = useState([])
    const [dataPartnerFiltered, setDataPartnerFiltered] = useState([])
    const [partnersList, setPartnersList] = useState([])
    const [operatorsList, setOperatorsList] = useState([])
    const [selectedPartners, setSelectedPartners] = useState('')
    const [isFiltered, setIsFiltered] = useState(false)
    const [selectedOperator, setSelectedOperator] = useState('')
    const [isFilteredOperator, setIsFilteredOperator] = useState(false)
    const [operatorsSchedule, setOperatorsSchedule] = useState([])
    const [totalOperator, setTotalOperator] = useState('')
    const [selectedMonth, setSelectedMonth] = useState('')
    const [showResultsToOperator, setShowResultsToOperator] = useState(false)
    
    const { userData } = useAuth()

    async function getData(partner) {

        setLoading(true)

        const dashboardData = await getDashboard(userData.token)
        if (dashboardData.error) {
            setLoading(false)
            setShowNotificationModalText(dashboardData.data)
            return
        }
        setDashData(dashboardData.data)
        let signatedContractData

        if (partner) {
            signatedContractData = await getSignatedContract(userData.token, partner?.id)
        } else {
            signatedContractData = await getSignatedContract(userData.token)
        }

        if (signatedContractData.error) {
            setLoading(false)
            return
        }

        setDataSignatedContract(Object.keys(signatedContractData.data).map((data) => {
            const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
            const translateMonth = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

            return {
                name: translateMonth[months.indexOf(data)],
                value: signatedContractData.data[data]
            }
        }))

        if (userData?.typeAccess_id === 1) {
            const partnersAPI = await getPartners(userData?.token)

            const newListPartners = partnersAPI.data?.filter(partner => {
                return partner?.isMaster == true
            })

            const operatorsListAPI = await getUsers(userData.token, 1)

            const usersFiltered = operatorsListAPI.data.filter(user => {
                return user?.typeAccess_id == 3
            })     
            
            setOperatorsList(usersFiltered.map(operator => {
                return {
                    ...operator,
                    fullname: `${operator?.firstname} ${operator?.lastname}`
                }
            }))
            setPartnersList(newListPartners)

        }

        if (userData?.typeAccess_id === 2) {

            const getOperatorsToSupervisorAPI = await getLinkedSupervisorToOperator(userData?.token, userData?.id)
            
            if (getOperatorsToSupervisorAPI.data.length > 0){
                setOperatorsList(getOperatorsToSupervisorAPI.data.map(operator => {
                    return {
                        ...operator,
                        fullname: `${operator?.firstname} ${operator?.lastname}`
                    }
                }))
            } else {
                const operatorsListAPI = await getUsers(userData.token, 1)
    
                const usersFiltered = operatorsListAPI.data.filter(user => {
                    return user?.typeAccess_id == 3
                })     
    
                setOperatorsList(usersFiltered.map(operator => {
                    return {
                        ...operator,
                        fullname: `${operator?.firstname} ${operator?.lastname}`
                    }
                }))
            }
            

        }

        if (userData?.typeAccess_id === 7){
            const partnersLinked = await getLinkePartnersWithConsultant(userData.token, userData.id)
            setPartnersList(partnersLinked.data)
        }

        setLoading(false)

    }

    useEffect(() => {

        getData()

    }, [])

    function backDashboard() {
        setShowScheduleTable(false)
        getData()
    }

    function selectDataForDashboard(params) {
        setSelectedDashboardData({
            status: params
        })
        setShowScheduleTable(true)
    }

    async function handlePartnerGraph(e) {
        setIsFiltered(true)
        getData(e)
    }

    async function handleOperatorGraph() {
                
        setIsFilteredOperator(true)

        const getAllScheduleOperatorAPI = await getDashboardToOperator(userData?.token, selectedOperator?.id, selectedMonth?.value)
        
        const newList = getAllScheduleOperatorAPI.data.map(item => {
            return {
                ...item,
                total: parseInt(item?.total),
                color: '#FFF'
            }
        })
        
        newList.map((item, index) => {
            if (index == 0){
                setTotalOperator(item?.total)
            }
        })
        
        setOperatorsSchedule(newList.filter((item, index) => {
            return index !== 0 
        }))
        
        setShowResultsToOperator(true)
        getData()
    }

    const monthList = [
        {
            name: 'Janeiro',
            value: 1
        },
        {
            name: 'Fevereiro',
            value: 2
        },
        {
            name: 'Março',
            value: 3
        },
        {
            name: 'Abril',
            value: 4
        },
        {
            name: 'Maio',
            value: 5
        },
        {
            name: 'Junho',
            value: 6
        },
        {
            name: 'Julho',
            value: 7
        },
        {
            name: 'Agosto',
            value: 8
        },
        {
            name: 'Setembro',
            value: 9
        },
        {
            name: 'Outubro',
            value: 10
        },
        {
            name: 'Novembro',
            value: 11
        },
        {
            name: 'Dezembro',
            value: 12
        }
    ]

    return (
        <>
            {
                showScheduleTable ?
                    <>
                        <div className='sm:flex hidden absolute right-4 top-4 gap-1'>
                            <Button onClick={() => { backDashboard(); setSelectedMode(0)}} approval={false} shadow={true}>Voltar ao Dashboard</Button>
                        </div>
                        <div className=' sm:hidden flex   right-4 top-96 gap-1 bg-bgPrimaryLight dark:bg-secondaryDefaultDark h-20'>
                            <Button onClick={() => { backDashboard(); setSelectedMode(0)}} approval={false} shadow={true}>Voltar ao Dashboard</Button>
                        </div>
                        {
                            [5, 6, 7].includes(userData?.typeAccess_id) ?
                                <PartnerSchedule dashboardData={selectedDashboardData} />
                                :
                                <OperatorSchedule dashboardData={selectedDashboardData} />
                        }
                    </>
                    :

                    <Container>
                        <TitlePage>
                            <Title text={'Dashboard'}></Title>
                        </TitlePage>
                        <Body background={false} shadow={false}>
                            {
                                loading ?
                                    <div className='flex flex-col h-96 items-center justify-center'>
                                        <DefaultLoader />
                                    </div>
                                    :
                                    <>
                                        <div className='flex flex-col lg:flex-row w-full gap-3 items-start lg:justify-center h-full'>                
                                            <div className='flex flex-col w-full max-w-[1950px] gap-3 lg:h-full'>
                                                <div className='shadow-lg mt-3 md:mt-0 w-full h-full p-8 bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-zinc-200 dark:border-primaryBorderDark rounded-lg'>
                                                    <h2 className='text-lg text-primaryDefaultLight dark:text-titleGrayTextDark font-bold mt-2 '>Olá usuário</h2>
                                                    <div className='grid grid-cols-4 mt-10'>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(4)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos sem direito (Mês)</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesNoRight}</p>
                                                        </div>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(1)}}className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos não compareceu (Mês)</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesNotAttend}</p>
                                                        </div>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(2)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos pendentes (Mês)</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesPendings}</p>
                                                        </div>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(3)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Contratos assinados (Mês)</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesSignatedContract}</p>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-4 mt-10'>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(9999)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Total de eventos deste mês</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesOfMonth}</p>
                                                        </div>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(5)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos reagendados</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesReTimes}</p>
                                                        </div>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(6)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos desmarcados</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesUnmarked}</p>
                                                        </div>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard(7)}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos sem interesse</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesNoInterest}</p>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-4 mt-10'>
                                                        <div onClick={() => { setSelectedMode(0);selectDataForDashboard('0')}} className='flex flex-col gap-2 w-full cursor-pointer'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Agendamentos novos</p>
                                                            <p className='text-base text-secondaryDefaultLight font-semibold'>{dashData.schedulesTimes}</p>
                                                        </div>                                                    
                                                    </div>
                                                </div>
                                                <div className='shadow-lg mt-3 md:mt-0 w-full h-full p-8 bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-zinc-200 dark:border-primaryBorderDark rounded-lg'>
                                                    <Title text={'Meu Dashboard'}></Title>
                                                </div>

                                                <div className='flex xl:flex-row flex-col md:flex gap-4'>
                                                    <div className={`shadow-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 lg:p-5 w-full ${![1,2].includes(userData?.typeAccess_id) ? 'xl:w-[100%]' : 'xl:w-[50%]'} border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg`}>
                                                        {/* <div className={'flex justify-between items-center'}> */}
                                                            <div className='flex flex-col items-start justify-start'>
                                                                {
                                                                    [1,2,7].includes(userData.typeAccess_id) &&
                                                                    <div className='flex justify-start items-center'>
                                                                        <p className='flex mb-2 dark:text-primaryDefaultDarkColor'>Resultados Parceiros</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    userData?.typeAccess_id === 1 || userData?.typeAccess_id === 7 &&
                                                                    <div className='flex flex-row justify-start items-center gap-1'>
                                                                        <label className='relative flex flex-col gap-1 items-start justify-center'>
                                                                            <InputAutoComplete
                                                                                data={partnersList}
                                                                                selectedLabel={'name'}
                                                                                optionList={['id', 'name']}
                                                                                placeHolder=' - '
                                                                                separator={'Todos'}
                                                                                onChange={e => {
                                                                                    setSelectedPartners(e);
                                                                                    if (typeof (e) == 'object') {
                                                                                        handlePartnerGraph(e)
                                                                                    }
                                                                                }}
                                                                                value={selectedPartners ? selectedPartners.name : ''}
                                                                                preSelectedValue={selectedPartners ? selectedPartners?.name : ''}
                                                                                id='partners'
                                                                                width={60}
                                                                            />
                                                                        </label>
                                                                        {
                                                                            userData?.typeAccess_id === 1 || userData?.typeAccess_id === 7 && isFiltered &&
                                                                            <Tippy
                                                                                content={<a>Limpar Filtros</a>}
                                                                                arrow={true}
                                                                                animation='shift-away'
                                                                                placement='top'
                                                                                delay={80}>
                                                                                <div className='cursor-pointer ml-2 text-xl sm:text-base'>
                                                                                    <FiSlash onClick={() => { setSelectedPartners(); getData(); setIsFiltered(false) }} />
                                                                                </div>
                                                                            </Tippy>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>                                                            
                                                        {/* </div> */}
                                                        <BarsGraph
                                                            title={`Contratos assinados ${new Date().getFullYear()}`}
                                                            data={dataSignatedContract}
                                                            bgGradient={true}
                                                            bgGradientFirstColor={'primaryDefaultLight'}
                                                            bgGradientSecondColor={'secondaryDefaultLight'}
                                                        />
                                                    </div>
                                                    {
                                                        [1,2].includes(userData?.typeAccess_id) &&
                                                        <>
                                                            <div className={`shadow-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 lg:p-6 w-full ${![1,2].includes(userData?.typeAccess_id) ? 'xl:w-[100%]' : 'xl:w-[50%]'} border border-zinc-200 dark:border dark:border-primaryBorderDark rounded-lg`}>
                                                                <div className='flex justify-start items-center'>
                                                                    <p className='flex mb-2 dark:text-primaryDefaultDarkColor'>Resultados Operador</p>
                                                                </div>
                                                                {
                                                                    [1,2].includes(userData?.typeAccess_id) &&
                                                                    <div className='flex flex-col justify-start items-center sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-1'>
                                                                        <label className='relative flex flex-col gap-1 items-start justify-center'>
                                                                            <InputAutoComplete
                                                                                data={operatorsList}
                                                                                selectedLabel={'fullname'}                                                                                
                                                                                optionList={['id', 'fullname']}
                                                                                placeHolder=' - '
                                                                                separator={'Operador'}
                                                                                onChange={e => setSelectedOperator(e)}
                                                                                value={selectedOperator ? selectedOperator?.fullname : ''}
                                                                                preSelectedValue={selectedOperator ? selectedOperator?.fullname : ''}
                                                                                id='operator'
                                                                                width={60}
                                                                            />
                                                                        </label>
                                                                        <label className='relative flex flex-col gap-1 items-start justify-center'>
                                                                            <InputAutoComplete
                                                                                data={monthList}
                                                                                selectedLabel={'name'}                                                                                
                                                                                optionList={['name']}
                                                                                separator={'Mês'}
                                                                                onChange={e => {
                                                                                    setSelectedMonth(e)
                                                                                }}
                                                                                value={selectedMonth ? selectedMonth?.name : ''}
                                                                                preSelectedValue={selectedMonth ? selectedMonth?.name : ''}
                                                                                id='month'
                                                                                width={28}
                                                                            />
                                                                        </label>
                                                                        <label className='relative flex flex-col gap-1 items-start justify-center mt-3 sm:mt-0'>
                                                                            <Tippy
                                                                                content={<a>Pesquisar</a>}
                                                                                arrow={true}
                                                                                animation='shift-away'
                                                                                placement='top'
                                                                                delay={80}>
                                                                                <div className='cursor-pointer ml-1 text-xl sm:text-base dark:text-gray-300'>
                                                                                    <FaSearch className='cursor-pointer' onClick={() => handleOperatorGraph()}/>                                                                                        
                                                                                </div>
                                                                            </Tippy>
                                                                        </label>
                                                                        {
                                                                            [1,2].includes(userData?.typeAccess_id) && isFilteredOperator &&
                                                                            <Tippy
                                                                                content={<a>Limpar Filtros</a>}
                                                                                arrow={true}
                                                                                animation='shift-away'
                                                                                placement='top'
                                                                                delay={80}>
                                                                                <div className='cursor-pointer ml-1 text-xl mt-3 sm:mt-0 sm:text-base dark:text-gray-300'>
                                                                                    <FiSlash onClick={() => { setSelectedOperator(); setSelectedMonth();getData(); setIsFilteredOperator(false); setShowResultsToOperator(false) }} />
                                                                                </div>
                                                                            </Tippy>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    showResultsToOperator ?
                                                                    <div className='flex flex-col justify-center items-center mt-10 animate-[wiggle_1s_ease-in-out] md:pb-20 lg:pb-0'>
                                                                        <NewSliceGraph
                                                                            title={`Resultados do mês (${selectedMonth?.name}/${new Date().getFullYear()})`}
                                                                            data={operatorsSchedule}
                                                                        />
                                                                        <p className='mt-5 dark:text-gray-300'>Total de agendamentos: {totalOperator}</p>
                                                                    </div>    
                                                                    : 
                                                                    <p className='mt-[15%] flex flex-col justify-center items-center text-center dark:text-gray-300'>
                                                                        Selecione um operador no campo "Operador" para obter resultados dele, referente ao mês selecionado ao lado.
                                                                    </p>
                                                                }
                                                            </div>
                                                        </>                                                      
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>

                            }


                        </Body>
                    </Container>

            }
        </>
    )
}