import { FaUserTie, FaPencilAlt, FaWrench, FaUsers, FaUserCheck, FaUsersCog, FaLink, FaTags, FaTag } from "react-icons/fa"

export const configItems = [

    {           
        "icon": <FaUserCheck/>,
        "name": "Usuários",
        "permission": [1],
        "subItems": [
            // {
            //     "path": "/clients",
            //     "name": "Clientes",
            //     "permission": [1,2,3,4,5,6],
            // },

            {
                "path": "/users",
                "name": "Usuários do sistema",
                "permission": [1],
            },
        ]
    },
    {
        "icon": <FaUsers/>,
        "name": "Parceiros",
        "permission": [1,4,5],
        "subItems": [
            {
                "path": "/partners",
                "name": "Empresas e Filiais",
                "permission": [1,4],
            },
            {
                "path": "/usersPartners",
                "name": "Usuários de parceiros",
                "permission": [1,4,5],
            },
            {
                "path": "/blockScheduleMany",
                "name": "Bloqueios de parceiros",
                "permission": [1,2],
            },
        ]
    },
    {
        "icon": <FaTags/>,
        "name": "Teses",
        "permission": [1,2],
        "subItems": [
            {
                "path": "/tags",
                "name": "Teses",
                "permission": [1,2],
            },
            {
                "path": "/confirmation",
                "name": "Confirmações",
                "permission": [1,2],
            }
        ]
    },
    {
        "icon": <FaUsersCog/>,
        "name": "Vínculos",
        "permission": [1,2],
        "subItems": [
            {
                "path": "/linkUsers",
                "name": "Parceiro",
                "permission": [1,2],
            },
            {
                "path": "/linkOperator",
                "name": "Operador",
                "permission": [1,2],
            },
            {
                "path": "/linkConsultant",
                "name": "Consultor",
                "permission": [1,2],
            },
        ]
    },
    {
        "icon": <FaWrench/>,
        "name": "Configurações",
        "permission": [1, 2 , 3, 4, 5, 6, 7],
        "subItems": [
            {
                "path": "/theme",
                "name": "Meu tema",
                "permission": [1, 2, 3, 4, 5, 6, 7],
            },
        ]
    },
]