import React, { useState } from "react";
import { useEffect } from "react";
//apexcharts
import ApexCharts from 'apexcharts'
//components
import { Container } from "../../components/container/container";
import { Body } from "../../components/container/Body";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Input } from "../../components/input/input"
import { LabelInput } from "../../components/label/label.input";
import { InputAutoComplete } from "../../components/input/input.autocomplete"
import { Button } from "../../components/buttons/button.default";
//api
import { api } from "../../services/api/api";
import { getPartners, getPartnersLinkedForUser, getLinkedSupervisorToOperator, getLinkedOperatorToSupervisor, getPartnersWithLinkedFromSupervisor } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { getClients } from "../../services/api/callAPIsFunctions/defaultCalls.api";
import { getUsers } from "../../services/api/callAPIsFunctions/defaultCalls.api";
import { getReportQuery } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//context
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useAuth } from "../../contexts/useAuth";
import { validateFields } from "../../utils/form.validator";
import { useThemeContext } from '../../contexts/themeContext'
import { responseError } from "../../utils/responsesFunctions/error.response";
import { convertURLtoFile } from "../../utils/formatImages/urlToFile";
import { useParams } from "react-router-dom";

export function Report() {
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenX } = useScreenSizeContext()
    const { userData } = useAuth()
    const [dateStart, setDateStart] = useState('')
    const [dateFinal, setDateFinal] = useState('')
    const [status, setStatus] = useState('')
    const statusList = [
        { description: 'Confirmado', id: 0 },
        { description: 'Não compareceu', id: 1 },
        { description: 'Pendente', id: 2 },
        { description: 'Contrato assinado', id: 3 },
        { description: 'Sem direito', id: 4 },
        { description: 'Reagendado', id: 5 },
        { description: 'Desmarcou', id: 6 },
        { description: 'Sem interesse', id: 7 },
    ]
    const [partner, setPartner] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [client, setClient] = useState('')
    const [clientList, setClientList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [User, setUser] = useState([])
    const [userParams, setUserParams] = useState([])

    useEffect(() => {
        async function run() {

            let isSupervisorWithLinks = false
            let filteredUsers = []

            // BUSCA POR CLIENTES
            const clientData = await getClients(userData.token)
            setClientList(clientData.data)

            
            let partnerData = await getPartners(userData.token)
            
        
            // REGRA PARA TRAZER APENAS PARCEIROS QUE ESTÃO VINCULADO AO SUPERVISOR
            if ([2].includes(userData.typeAccess_id)) {
                let idsPartnersLink = []

                const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
                partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

                if (partnersLinked.data.length > 0) {
                    isSupervisorWithLinks = true
                    let filteredPartnersList = []

                    partnerData.data.map(partner => {
                        if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                            filteredPartnersList.push(partner?.id)
                        }
                    })

                    partnerData.data = partnerData.data.filter(partner => {
                        if (filteredPartnersList.includes(partner?.id)) {
                            return partner
                        }
                    })
                }
            }

            if ([3].includes(userData.typeAccess_id)) {
                
                const linkedSupervisorAPI = await getLinkedOperatorToSupervisor(userData.token, userData.id)

                if (linkedSupervisorAPI.data.length > 0){
                    const partnersToSupervisor = await getPartnersWithLinkedFromSupervisor(userData.token)
                    partnerData.data = partnersToSupervisor.data
                }

            }

            if (isSupervisorWithLinks) {
                const getOperatorsToSupervisor = await getLinkedSupervisorToOperator(userData.token, userData?.id)
                filteredUsers = getOperatorsToSupervisor?.data
            } else {
                const usersData = await getUsers(userData.token, 1)
                filteredUsers = usersData.data.length > 0 ? usersData.data.filter(user => user.id > 1) : []
            } 
            
            setUsersList(filteredUsers?.map(user => {
                return {
                    ...user,
                    nameInput: `${user?.firstname} ${user?.lastname?.split(' ')[0]}`
                }
            }))
            setPartnerList(partnerData.data)
            
        }

        run()
    }, [])

    async function handleSubmit() {

        const requiredFields = [
            { name: 'initialDate', value: dateStart, required: true, type: 'string' },
            { name: 'endDate', value: dateFinal, required: true, type: 'string' },

        ]
        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            //reportQuery
            const scheduleAttchaments = await api.get(
                `/api/v1/schedule/export?startDate=${dateStart}&finishDate=${dateFinal}` + userParams.join(''),
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    },
                    responseType: 'blob',
                })

            const url = window.URL.createObjectURL(scheduleAttchaments.data)
            const downloadElement = document.createElement('a')
            downloadElement.setAttribute('href', url)
            downloadElement.setAttribute('download', `Relatorio_${new Date().toISOString().split('T')[0]}`)
            downloadElement.click()


        } catch (error) {

        }
    }

    function handleNewParam(param, setState, data) {

        let newParams = userParams
        newParams = userParams.filter(filter => {
            if (filter.indexOf(param) === -1) {
                return filter
            }
        })

        newParams.push(`&${param}=${data.id}`)
        setUserParams(newParams)

        setState(data)

    }
    return (

        <Container>
            <TitlePage>
                <Title text={'Relatórios'}>
                </Title>
            </TitlePage>
            <Body>
                <div className="flex lg:flex-row flex-col w-full gap-3">
                    <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start gap-3">
                        <h2 className='flex md:flex justify-center items-center lg:justify-start lg:items-start text-md lg:text-left text-center font-bold text-primaryDefaultLight dark:text-primaryDefaultDarkColor'>Preencha os campos para gerar o relatorio:</h2>

                        <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">
                            <LabelInput text={'Data de inicio *'} >
                                <Input id='endDate' type={'date'} onChange={(e) => setDateStart(e.target.value)} value={dateStart} width={screenX > 769 ? '122px' : '224px'} />
                            </LabelInput>
                            <LabelInput text={'Data final: *'}>
                                <Input id='initialDate' type={'date'} onChange={(e) => setDateFinal(e.target.value)} value={dateFinal} width={screenX > 769 ? '122px' : '224px'} />
                            </LabelInput>
                        </div>
                        <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">

                            <LabelInput text={'Status'}>
                                <InputAutoComplete type={'text'} data={statusList} onChange={(e) => handleNewParam('status', setStatus, e)} selectedLabel={'description'} optionList={['id', 'description']} width={64} />
                            </LabelInput>
                            <LabelInput text={'Parceiro'}>
                                <InputAutoComplete type={'text'} data={partnerList} onChange={(e) => handleNewParam('partners_id', setPartner, e)} selectedLabel={'name'} placeHolder={' - '} optionList={['id','name']} width={64} />
                            </LabelInput>
                        </div>
                        <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">

                            <LabelInput text={'Cliente'}>
                                <InputAutoComplete type={'text'} data={clientList} onChange={(e) => handleNewParam('clients_id', setClient, e)} selectedLabel={'firstname'} placeHolder={' - '} optionList={['id','firstname']} width={64} />
                            </LabelInput>
                            <LabelInput text={'Usuário'}>
                                <InputAutoComplete 
                                    type={'text'} 
                                    disabled={userData?.typeAccess_id === 3 ? true : false} 
                                    data={usersList} 
                                    onChange={(e) => handleNewParam('users_created', setUser, e)} 
                                    preSelectedValue={userData?.typeAccess_id === 3 ? usersList?.filter(e => {return e?.id === userData?.id})[0]?.firstname : ''}
                                    selectedLabel={'nameInput'} 
                                    placeHolder={' - '} 
                                    optionList={['id','nameInput']} 
                                    width={64} 
                                />
                            </LabelInput>
                        </div>
                        <div className="mt-2 pb-2">
                            <Button onClick={() => handleSubmit()} approval={true}>Gerar relatório</Button>
                        </div>
                    </div>
                </div>
            </Body>
        </Container>

    )
}