import React, { useEffect, useState } from 'react'
import randomColor from 'randomcolor';
import { useThemeContext } from '../../contexts/themeContext';
import ApexCharts from 'apexcharts'
import style from '../dashboard/style/dashboard.module.css'

//api

import { getDashboard } from '../../services/api/callAPIsFunctions/defaultCalls.api';
export function NewSliceGraph({ data, title }) {

    const { primaryDefaultLight, secondaryDefaultLight } = useThemeContext()
    const [objectColor, setObjectColor] = useState([])

    useEffect(() => {

        if (data) {

            const luminosityFill = document.getElementsByClassName('dark').length > 0 ? 'dark' : 'light'
            
            const dataObject = []
            for (let i = 0; i < data.length; i++) {

                if (i < 200) {
                    dataObject.push(data[i])
                }
            }

            let colors = []

            data.map((item, index) => {
                if (index == 0){
                    colors.push('#0a68cc')
                } else if (index == 1){
                    colors.push('#fdba74')
                } else if (index == 2){
                    colors.push('#fca5a5')
                } else if (index == 3){
                    colors.push('#14b8a6')
                } else if (index == 4){
                    colors.push('#FEF08A')
                } else if (index == 5){
                    colors.push('#0ea5e9')
                } else if (index == 6){
                    colors.push('#b91c1c')
                } else if (index == 7){
                    colors.push('#9ca3af')
                }
            })

            colors.map((color, index) => {
                dataObject[index].color = color
            })
            setObjectColor([...dataObject])
            var options = {
                stroke: {
                    show: false,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 0,
                    dashArray: 0,
                },

                colors: colors.map(col => col),

                series: data.map(dataTotal => dataTotal.total),
                labels: data.map(dataLabel => dataLabel.name),
                chart: {

                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    },
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 400,
                    options: {
                        chart: {
                            width: 350
                        },

                        legend: {
                            show: true,
                            position: 'bottom',
                            horizontalAlign: 'center',
                            labels: {
                                colors: [`'${primaryDefaultLight}'`],
                                useSeriesColors: true
                            }

                        }
                    }
                }]
            };

            var chart = new ApexCharts(document.querySelector("#chartSlice"), options);
            chart.render();
        }

    }, [primaryDefaultLight, secondaryDefaultLight])

    return (
        <div id='SvgjsG1013' className={`sm:w-[400px] ${style.apexcharts} `}>
            <h2 className='text-titleBlackTextLight dark:text-titleGrayTextDark mb-7 flex items-center justify-center'>{title}</h2>
            <div id='chartSlice' className='stroke-transparent'>
            {/* <canvas width="200" height="200"></canvas>
            <div className='grid grid-cols-2 mt-10 borderitems-center justify-center'>
                {
                    objectColor.map((thisData) => {

                        let color = thisData.color
                        return <div className='ml-4 flex flex-row gap-1 items-center justify-start'>
                            <div style={{ background: color }} className='h-4 w-4'></div>
                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{thisData.name}</p>
                        </div>
                    })
                }
            </div> */}
            </div>
        </div>
    )
}
