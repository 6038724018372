//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// Context
import { useThemeContext } from '../../contexts/themeContext'
import { useAuth } from '../../contexts/useAuth'
import { useSocket } from '../../contexts/socketContext'
//components
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import { TableDefault } from '../../components/table/table.default'
import { TableMobile } from '../../components/table/table.mobile'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import moment from 'moment'
import 'moment/locale/pt-br'
import { getClients, getPartnersWithLinkedFromSupervisor, getLinkedOperatorToSupervisor ,getPartnersHours, getPartnersLinkedForUser, getScheduleByDate, getUsers, getLinkedSupervisorToOperator } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { LabelInput } from '../../components/label/label.input'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { Button } from '../../components/buttons/button.default'
import { ScreenModal } from '../../components/modals/notification/screenModal'
import { OperatorScheduleCreate } from './operator.schedule.create'
import { OperatorScheduleEdit } from './operator.schedule.edit'
import { api } from '../../services/api/api'
import { schedulesStatusList } from '../../utils/schedulesStatusList'
import { maskHoursForTable } from '../../utils/mask/maskHours'
import { formattTimeToShow } from '../../utils/formatData/formatTimeToShow'
import { FiEye, FiSlash } from 'react-icons/fi'
import { FaCalendarAlt, FaEdit, FaEye, FaFilter } from 'react-icons/fa'
import { NotificationModal } from '../../components/modals/notification/notification.modal'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { LoadingPulse } from '../../components/loaders/loadingPulse'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { Input } from '../../components/input/input'

export function OperatorSchedule({ dashboardData }) {

    const [loading, setLoading] = useState(true)
    // const [loadingClients, setLoadingClients] = useState(true)
    const navigate = useNavigate()
    const { screenY, screenX } = useScreenSizeContext()
    const { socketConnection } = useSocket()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()

    const [newSchedule, setNewSchedule] = useState(false)
    const [pages, setPages] = useState({})

    const [filteredEvents, setFilteredEvents] = useState([])
    const [inEditEvent, setInEditEvent] = useState({ inEdit: false, event: `` })
    const [partnerList, setPartnerList] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [confirmationList, setConfirmationList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [filteredAddressStatePartnersList, setFilteredAddressStatePartnersList] = useState([])
    const [filteredAddressCity, setFilteredAddressCities] = useState([])
    const [selectedPartner, setSelectedPartner] = useState('')
    const [hasUpdate, setHasUpdate] = useState(false)

    //table mode
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [startDate, setStartDate] = useState('')
    const [finishDate, setFinishDate] = useState('')
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [filterActive, setFilterActive] = useState(false)
    const [buttonFilter, setButtonFilter] = useState(false)
    const [selectedTag, setSelectedTag] = useState('')
    const [selectedConfirmation, setSelectedConfirmation] = useState('')

    useEffect(() => {
        if (dashboardData){
            let startMonth = moment().startOf('month').format('YYYY-MM-DD')
            let endMonth = moment().endOf('month').format('YYYY-MM-DD')
            let status = schedulesStatusList.filter(e => e?.id == dashboardData?.status)
            setSelectedStatus(status[0])
            setStartDate(moment(startMonth))
            setFinishDate(moment(endMonth))
            
            let isOperator = ''
            if (userData?.typeAccess_id == 3){
                isOperator = `&users_created=${userData?.id}`
            }
            
            setFilterActive(true)
            getData(`startDate=${startMonth}&finishDate=${endMonth}${dashboardData?.status == 9999 ? '' : `&status=${dashboardData?.status}`}${isOperator ? isOperator : ''}`, 1)

        } else {
            getData('', 1)
        }
    }, [])

    useEffect(() => {
        if (!showUniversalModal){
            setButtonFilter(false)
            setInEditEvent({ ...inEditEvent, inEdit: false, event: `` })
            setNewSchedule(false)
        }
    }, [showUniversalModal])

    useEffect(() => {
         
        socketConnection?.on('schedule:create', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
        socketConnection?.on('schedule:update', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
        socketConnection?.on('schedule:updateStatus', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
        socketConnection?.on('schedule:remove', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
    
        return () => {
            socketConnection?.off('schedule:create')
            socketConnection?.off('schedule:update')
            socketConnection?.off('schedule:updateStatus')
            socketConnection?.off('schedule:remove')
        }

    }, [socketConnection])

    async function getData(params, page) {
        setLoading(true)
        setHasUpdate(false)

        const getTagsAPI = await api.get('/api/v1/tags?active=true', {
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        })
        setTagsList(getTagsAPI?.data?.data?.map(item => {
            return {
                ...item,
                inputLabel: `${item?.id} - ${item?.description}`
            }
        }))

        const getConfirmationAPI = await api.get('/api/v1/confirmation?active=true', {
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        })
        setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
            return {
                ...item,
                inputLabel: `${item?.id} - ${item?.description}`
            }
        }))

        let endpoint = params ? '/api/v1/schedule' + `?` + params : `/api/v1/schedule`
        
        try {

            const users = await getUsers(userData.token, 1)
            let filteredUsers = users.data.length > 0 && userData?.typeAccess_id != 3 ? users.data.filter(user => user.id > 1) : users.data.length > 0 && userData?.typeAccess_id == 3 ? users.data.filter(user => user.id == userData?.id) : []

            let partnerListData = await getPartnersHours(userData?.token)

            // REGRA PARA SUPERVISORES VINCULADOS COM PARCEIROS
            if ([2].includes(userData.typeAccess_id)) {
                const getOperatorsToSupervisor = await getLinkedSupervisorToOperator(userData.token, userData?.id)
                if (getOperatorsToSupervisor.data.length > 0){
                    filteredUsers = getOperatorsToSupervisor?.data
                }

                let idsPartnersLink = []

                const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
                partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

                if (partnersLinked.data.length > 0) {
                    let filteredPartnersList = []

                    partnerListData.data.map(partner => {
                        if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                            filteredPartnersList.push(partner?.id)
                        }
                    })

                    partnerListData.data = partnerListData.data.filter(partner => {
                        if (filteredPartnersList.includes(partner?.id)) {
                            return partner
                        }
                    })
                }
            }

            // REGRA PARA OPERADOR COM VINCULO NO SUPERVISOR, PARA PEGAR EMPRESA COM BASE NO SUPERVISOR
            if ([3].includes(userData?.typeAccess_id)) {
                const linkedSupervisorAPI = await getLinkedOperatorToSupervisor(userData.token, userData.id)
                if (linkedSupervisorAPI.data.length > 0){
                    const partnersToSupervisor = await getPartnersWithLinkedFromSupervisor(userData.token)
                    partnerListData.data = structuredClone(partnersToSupervisor.data)
                }
            }
            
            // setIsLinkedUser(partnerListData.data.map(partner => { return partner?.id }))
            setPartnerList(structuredClone(partnerListData?.data))

            let stateArr = []
            let statesIncluded = []
            let citiesArr = []
            let citiesIncluded = []
            partnerListData?.data.map(partner => {

                if (!statesIncluded.includes(partner?.addressState)) {
                    stateArr.push({ addressState_externalId: partner?.addressState_externalId, addressState: partner?.addressState })
                    statesIncluded.push(partner?.addressState)
                }
                if (!citiesIncluded.includes(partner?.addressCity_externalId) && partner?.addressState_externalId.slice(0, 2) === partner?.addressState_externalId) {
                    citiesArr.push({ addressCity_externalId: partner?.addressCity_externalId, addressCity: partner?.addressCity })
                    citiesIncluded.push(partner?.addressCity_externalId)
                }
            })
            setFilteredAddressStatePartnersList(stateArr.sort((a, b) => a?.addressState_externalId - b?.addressState_externalId))
            setFilteredAddressCities(citiesArr.sort((a, b) => a?.addressCity_externalId - b?.addressCity_externalId))

            const events = await api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })

            let arrayPages = Array(events.data.totalPages).fill(0)
            let formattedArray = []
            arrayPages.map((page, index) => {
                formattedArray.push(index + 1)
            })

            setFilteredEvents(events.data.data.map(event => {
                const statusDescription = schedulesStatusList.filter(e => e?.id === event?.status ? e?.description : '')
                const formatDateSchedule = event?.scheduleDate.split('T')[0].split('-')
                return {
                    ...event,
                    schedulesDescription: `${maskHoursForTable(event?.startHour)} às ${maskHoursForTable(event?.finishHour)} - ${formatDateSchedule[2]}/${formatDateSchedule[1]}/${formatDateSchedule[0]}`,
                    created_at: formattTimeToShow(event?.created_at),
                    statusDescription: statusDescription[0]?.description,
                    action: (
                        <div className='w-full items-center justify-center text-center flex'>
                            <Tippy content={'Editar'}
                                arrow={true}
                                animation='perspective'
                                placement='top'
                                delay={50}>
                                <div className='cursor-pointer'>
                                    <FaEdit
                                        className='text-sm text-blue-700'
                                        onClick={(e) => { handleEdit(event) }}
                                    />
                                </div>
                            </Tippy>
                        </div>
                    )
                }
            }))
            setPages({
                totalPages: arrayPages?.length,
                actualPage: params ? page : 1,
                page: formattedArray
            })

            setUsersList(filteredUsers?.map(user => {
                return {
                    ...user,
                    nameInput: `${user?.firstname} ${user?.lastname?.split(' ')[0]}`
                }
            }))
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    }

    function handleNewPage(page) {

        let params = []

        selectedClient?.id && params.push(`clients_id=${selectedClient?.id}`)
        selectedUser?.id && params.push(`users_created=${selectedUser?.id}`)
        selectedPartner?.id && params.push(`partners_id=${selectedPartner?.id}`)
        selectedStatus?.id ? params.push(`status=${selectedStatus?.id}`) : ''
        startDate && params.push(`startDate=${startDate.format('YYYY-MM-DD')}`)
        finishDate && params.push(`finishDate=${finishDate.format('YYYY-MM-DD')}`)
        selectedState && params.push(`addressState=${selectedState}`)
        selectedCity && params.push(`addressCity=${selectedCity}`)
        params = params.join('&')

        getData(`${params}&page=${page}`, page)
    }

    async function clearStatesModeTable() {
        setSelectedClient('')
        setSelectedUser('')
        setSelectedPartner('')
        setStartDate('')
        setFinishDate('')
        setSelectedStatus('')
        setSelectedState('')
        setSelectedCity('')
        setSelectedTag('')
        setSelectedConfirmation('')
    }

    async function handleSchedulesReport() {

        let yearElement = document.getElementById('startDate')
        let monthElement = document.getElementById('finishDate')

        if (finishDate < startDate) {
            setFinishDate('')
            setStartDate('')
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            monthElement.style.border = '1px solid red'
            yearElement.style.border = '1px solid red'
            return setShowNotificationModalText('Data final não pode ser menor que a data inicial')
        }

        setShowModificationModal(false)
        monthElement.style.border = ''
        yearElement.style.border = ''

        let params = []

        selectedClient && params.push(`clients_name=${selectedClient}`)
        selectedUser?.id && params.push(`users_created=${selectedUser?.id}`)
        selectedPartner?.id && params.push(`partners_id=${selectedPartner?.id}`)
        selectedStatus !== '' && params.push(`status=${selectedStatus?.id}`)
        selectedState && params.push(`addressState=${selectedState}`)
        selectedCity && params.push(`addressCity=${selectedCity}`)
        startDate && params.push(`startDate=${startDate.format('YYYY-MM-DD')}`)
        finishDate && params.push(`finishDate=${finishDate.format('YYYY-MM-DD')}`)
        selectedTag && params.push(`tags_id=${selectedTag?.id}`)
        selectedConfirmation && params.push(`confirmation_id=${selectedConfirmation?.id}`)
        params = params.join('&')

        setFilterActive(true)
        getData(params, 1)
    }

    function editEvent(e) {
        setInEditEvent({ ...inEditEvent, inEdit: true, event: e })
        setShowModificationModal(false)
        setShowUniversalModal(true)
    }

    async function handleEdit(e){
        editEvent(e)
    }

    const tableThead = [
        {
            "name": 'Agendado por',
            "original_name": 'users_created_name'
        },
        {
            "name": 'Cliente',
            "original_name": 'clients_name'
        },
        {
            "name": 'Parceiro',
            "original_name": 'partners_name'
        },
        {
            "name": 'Agendado',
            "original_name": 'schedulesDescription'
        },
        {
            "name": 'Status',
            "original_name": 'statusDescription'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Ação',
            "original_name": 'action'
        }
    ]

    return (
        <>
            <div className={`${hasUpdate ? 'flex overflow-hidden' : 'hidden overflow-hidden'}`}>
                <div className={`bg-orange-300`}>
                    <div className={`${hasUpdate && 'Right_To_Center'} bg-orange-300 flex flex-row justify-center duration-200 z-[999] transition-all right-0 top-0 w-[20rem] p-2 absolute rounded-md opacity-1 mt-[4.8rem] mr-[1rem] shadow-xl h-[5rem]`}>
                        <div className={`flex justify-center items-center text-white flex-col`}>
                            <p className='text-sm'>Atualizações disponíveis</p>
                            <p className='text-sm'>
                                <a className='underline text-black cursor-pointer hover:text-white duration-200' onClick={() => {setHasUpdate(false); getData('', 1)}}>Clique aqui </a> 
                                para atualizar
                            </p>
                        </div>
                        <Tippy
                            content={'Fechar'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={80}>
                            <button className={`w-[2rem] h-[2rem] absolute justify-center items-center right-0 border-none rounded-lg outline-none cursor-pointer opacity-1 mr-[0.2rem] pb-[2px] pl-[1px] hover:duration-[200ms] hover:opacity-5`} onClick={() => {setHasUpdate(false)}}>x</button>                            
                        </Tippy>
                    </div>
                </div>
            </div>
            <Container>
                {
                    buttonFilter && showUniversalModal ?
                    <ScreenModal title={'Filtros'}>
                        {
                            loading ? 
                            <div className='flex flex-row items-center justify-center w-96 ml-[-3rem] h-40'>
                                <LoadingPulse />
                                <p className='text-sm'>Carregando dados...</p>
                            </div>
                            : 
                            <div className='flex flex-col pt-2 pb-5 px-12 gap-1 items-center'>
                                    <LabelInput text={`Cliente`}>
                                        <Input 
                                            onChange={(e) => setSelectedClient(e?.target?.value)}
                                            width={80}
                                            value={selectedClient ? selectedClient : ''}
                                        />
                                    </LabelInput>
                                    {
                                        // userData?.typeAccess_id !== 3 &&
                                        <LabelInput text={`Agendado por`}>
                                            <InputAutoComplete 
                                                onChange={(e) => setSelectedUser(e)} 
                                                preSelectedValue={selectedUser ? selectedUser?.firstname : ''} 
                                                width={80} data={usersList} 
                                                selectedLabel={'nameInput'} 
                                                optionList={['id', 'nameInput']} 
                                            />
                                        </LabelInput>
                                    }
                                    <LabelInput text={`Parceiro`}>
                                        <InputAutoComplete onChange={(e) => setSelectedPartner(e)} preSelectedValue={selectedPartner ? selectedPartner?.name : ''} width={80} data={partnerList} selectedLabel={'name'} optionList={['id', 'name']} />
                                    </LabelInput>
                                    <LabelInput text={`Status`}>
                                        <InputAutoComplete onChange={(e) => setSelectedStatus(e)} width={80} value={selectedStatus && selectedStatus?.description} data={schedulesStatusList} selectedLabel={'description'} optionList={['id', 'description']} />
                                    </LabelInput>
                                    <LabelInput text={'Tese'}>
                                        <InputAutoComplete 
                                            value={selectedTag ? selectedTag?.description : ''}
                                            preSelectedValue={selectedTag ? selectedTag?.description : ''}
                                            width={80} 
                                            data={tagsList}
                                            selectedLabel={`description`} 
                                            optionList={['inputLabel']} 
                                            onChange={(e) => {setSelectedTag(e)}}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Confirmação'}>
                                        <InputAutoComplete 
                                            value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                            preSelectedValue={selectedConfirmation ? selectedConfirmation?.description : ''}
                                            width={80}
                                            data={confirmationList} 
                                            selectedLabel={`description`} 
                                            optionList={['inputLabel']} 
                                            onChange={(e) => {setSelectedConfirmation(e)}}
                                        />
                                    </LabelInput>  
                                    <LabelInput text={'Estado'}>
                                        <InputAutoComplete
                                            value={selectedState ? selectedState : ''} 
                                            preSelectedValue={selectedState} 
                                            width={80}                                                    
                                            data={filteredAddressStatePartnersList}
                                            selectedLabel={`addressState`} 
                                            optionList={[`addressState_externalId`, `addressState`]} 
                                            onChange={(e) => setSelectedState(e?.addressState)} 
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Cidade'}>
                                        <InputAutoComplete 
                                            value={selectedCity ? selectedCity : ''}
                                            preSelectedValue={selectedCity} 
                                            width={80} 
                                            data={filteredAddressCity} 
                                            selectedLabel={`addressCity`} 
                                            optionList={[`addressCity_externalId`, `addressCity`]} 
                                            onChange={(e) => {setSelectedCity(e?.addressCity)}}
                                        />
                                    </LabelInput>
                                    <LabelInput text={`Data inicial`}>
                                        <input type='date' id={'startDate'} className="w-80 text-base h-8 text-primaryDefaultLight p-1 border border-titleGrayTextDark dark:text-primaryDefaultDarkColor dark:bg-secondaryDefaultDark" value={startDate && startDate?.clone()?.format('YYYY-MM-DD')} onChange={(e) => setStartDate(moment(e.target.value))} />
                                    </LabelInput>
                                    <LabelInput text={`Data final`}>
                                        <input type='date' id={'finishDate'} onChange={(e) => setFinishDate(moment(e.target.value))} className="w-80 h-8 p-1 border border-titleGrayTextDark text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor dark:bg-secondaryDefaultDark" value={finishDate && finishDate?.clone()?.format('YYYY-MM-DD')} />
                                    </LabelInput>
                                    <div className='mt-6'>
                                        <Button shadow={true} onClick={() => {setShowUniversalModal(false); handleSchedulesReport()}}>Buscar</Button>
                                    </div>
                            </div>
                        }
                    </ScreenModal>
                    :
                    <ScreenModal>
                        {
                            inEditEvent.inEdit ?
                            <OperatorScheduleEdit selectedEvent={inEditEvent.event} getData={() => getData()}/>
                            :
                            newSchedule &&
                            <OperatorScheduleCreate setHasUpdate={setHasUpdate}/>
                        }
                    </ScreenModal>
                }
                <TitlePage dontShowNotification={newSchedule ? true : !inEditEvent.inEdit ? false : !inEditEvent.inEdit}>
                    <div className='flex flex-col md:flex-row px-2 justify-start lg:justify-start gap-2 items-center w-full'>
                        <div className='flex flex-row gap-2 w-full'>
                            <Title text={'Agenda'}></Title>
                            <Button onClick={() => { setNewSchedule(true); setShowUniversalModal(true) }} shadow={true}>Novo agendamento</Button>
                            {showUniversalModal ? <></> : <NotificationModal dontShow={true} isAuth={true} />}
                        </div>
                    </div>
                </TitlePage>
                <Body dontAnimate={true} hasFooter={dashboardData && screenX > 640 ? true : false}>
                    <div className='flex flex-col items-start justify-start w-full '>
                        <div className='flex flex-row w-full items-center justify-between gap-3'>
                            <div className='flex flex-row items-center'>
                                <Button onClick={() => {setButtonFilter(true); setShowUniversalModal(true)}}>
                                    <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                                </Button>
                                <div className='flex items-center justify-center cursor-pointer text-lg ml-3'>
                                    {
                                        filterActive &&
                                        <Tippy
                                            content={<a>Limpar Filtros</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <div>
                                                <FiSlash onClick={() => { getData(); clearStatesModeTable(); setFilterActive(false) }} />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            loading ?
                            <div className='flex flex-row items-center justify-center w-full'>
                                <LoadingPulse />
                                <p className='text-sm'>Carregando dados...</p>
                            </div>
                            :
                            <>
                                <div id='SchedulesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full mt-8 boxShadow  overflow-y-auto rounded-lg'>
                                    <TableDefault
                                        onClick={(e) => {}}
                                        title={tableThead}
                                        cursorPointer={true}
                                        fieldFilter={true}
                                        data={filteredEvents}
                                        collumns={["users_created_name", "clients_name", "partners_name", "schedulesDescription", "statusDescription", "created_at", "action"]}
                                    />
                                    <div className='flex flex-row w-full items-end justify-end px-2'>
                                        {
                                            pages?.page?.map((thisPage, index) => {
                                                const totalPages = pages?.page.length

                                                const buttonTrue = <a onClick={() => handleNewPage(thisPage)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                const buttonFalse = <a onClick={() => handleNewPage(thisPage)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                let existsPoints = false
                                                let showPointers = false

                                                if (totalPages < 6) {
                                                    return pages.actualPage === thisPage ?
                                                        buttonTrue
                                                        :
                                                        buttonFalse
                                                } else {
                                                    existsPoints = true
                                                    if (index + 1 === 1 && pages.actualPage !== thisPage) {
                                                        return buttonFalse
                                                    } else if (index + 1 == thisPage && pages.actualPage === thisPage) {
                                                        return buttonTrue
                                                    } else if (pages.actualPage + 3 > index + 1 && pages.actualPage - 2 < index + 1) {
                                                        return buttonFalse
                                                    } else if (totalPages === thisPage) {
                                                        return buttonFalse
                                                    } else {
                                                        if (pages.actualPage + 3 < index + 1 || pages.actualPage - 2 > index + 1) {
                                                            showPointers = true
                                                        }
                                                        if (existsPoints && showPointers == false) {
                                                            return <a>...</a>
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                <div id='SchedulesMobileTableContent' className='sm:hidden flex flex-col items-center justify-center w-full mt-8 boxShadow  overflow-y-auto rounded-lg'>
                                    <TableMobile
                                        onClick={(e) => e.func.execute(e.data)}
                                        title={tableThead}                                                                                                        
                                        data={filteredEvents}                                                    
                                        functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}                                                    
                                        collumns={["users_created_name", "clients_name", "partners_name", "schedulesDescription", "statusDescription", "formatHasConfirmed", "created_at"]}
                                    />
                                    <div className='flex flex-row w-full items-end justify-end px-2'>
                                        {
                                            pages?.page?.map((thisPage, index) => {
                                                const totalPages = pages?.page.length

                                                const buttonTrue = <a onClick={() => handleNewPage(thisPage)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                const buttonFalse = <a onClick={() => handleNewPage(thisPage)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                let existsPoints = false
                                                let showPointers = false

                                                if (totalPages < 6) {
                                                    return pages.actualPage === thisPage ?
                                                        buttonTrue
                                                        :
                                                        buttonFalse
                                                } else {
                                                    existsPoints = true
                                                    if (index + 1 === 1 && pages.actualPage !== thisPage) {
                                                        return buttonFalse
                                                    } else if (index + 1 == thisPage && pages.actualPage === thisPage) {
                                                        return buttonTrue
                                                    } else if (pages.actualPage + 3 > index + 1 && pages.actualPage - 2 < index + 1) {
                                                        return buttonFalse
                                                    } else if (totalPages === thisPage) {
                                                        return buttonFalse
                                                    } else {
                                                        if (pages.actualPage + 3 < index + 1 || pages.actualPage - 2 > index + 1) {
                                                            showPointers = true
                                                        }
                                                        if (existsPoints && showPointers == false) {
                                                            return <a>...</a>
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Body>
            </Container>
        </>
    )
}